<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                东北证券走访非凸科技，共话量化行业发展未来
              </p>
              <div class="fst-italic mb-2">2023年6月27日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >东北证券</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/42/01-东北证券.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    近日，东北证券一行骨干莅临非凸科技上海总部参观交流，就行业发展、算法交易、客户需求、实践案例、非凸算法生态等方面展开深入探讨，并一同展望了未来合作方向。
                  </p>
                  <p>
                    东北证券始终坚持“客户为上”的经营发展理念，积极推动产品创新和服务，真正做到自身能力与用户需求的持续对接。未来，东北证券将全力构建金融科技赋能下的双轮驱动业务体系，持续践行企业社会责任，为客户投资理财提供全方位、多元化、专业化的金融服务，推动公司可持续高质量发展，朝着建设一流现代金融服务商的目标奋进。东北证券始终是非凸科技重要的合作伙伴，强强联合，共同发展。在未来，双方将加强更多创新业务的合作，朝着更加紧密、适应行业发展趋势的方向，共赢共生。
                  </p>
                  <p>
                    非凸科技每年投入千万元超算研发成本，坚持算力创新、技术突破、行业洞察，集结大量工程开发和策略研究人员，将每一环节做到极致，实现了绩效表现的真正提升，也得到了数百家机构客户的专业认可。除此之外，非凸科技一站式算法业务解决方案凭借全方位、细节化的操作流程，以及过硬的技术实力，被越来越多的金融机构所选择。在与东北证券合作过程中，双方充分发挥了各自投研能力，优势互补，为机构客户提供了更精准、更便捷、更切实的交易价值和体验。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/42/02-非凸.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    在交流会上，非凸科技商务总监朱家辉从客户需求的角度，通过实践案例分析了当前客户比较关注“绩效表现、系统稳定性、使用便捷度、费率情况”等因素。朱家辉表示，“非凸算法融合了先进的量化模型，结合人工智能、机器学习等科技手段，采用Rust全栈技术，拥有高胜率量价预判能力，大幅降低了交易成本，为用户超高额收益创造了条件，现已在十余家头部券商全面上线。非凸科技正在用行动证明，选择我们是正确的。”
                  </p>
                  <p>
                    随后，非凸科技机构业务经理周升从客户进阶需求出发，详细介绍了非凸算法生态体系。周升表示，“非凸算法生态围绕非凸算法总线系统，从交易场景的真实需求出发，结合自身算法优势，为券商等大型金融机构打造一站式算法业务解决方案。与此同时，非凸科技也希望通过算法交易实践的沉淀，基于更懂业务的产品、更为先进的技术，持续为量化行业生态建设赋能。”
                  </p>
                  <p>
                    未来，非凸科技将持续关注行业动态，升级优化解决方案，为金融机构提供更为优质的产品和服务。也将吸引和汇聚行业更多优质资源，开放创新，共同成长，推动量化科技行业提速发展。
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News42",
};
</script>

<style></style>
